<template>
  <div class="item_list">
    <van-nav-bar
      title="选择商品"
      left-arrow
      safe-area-inset-top
      @click-left="navBack"
    />
    <van-search
      v-model="searchVal"
      placeholder="请输入关键字"
      show-action
      @search="search"
      @input="onfilter"
    />
    <van-list class="fixheight">
      <item-card-goods
        v-for="(item, index) in goodsListFilted"
        :key="index"
        :goods="item"
        :store="item.store"
        :selectable="true"
        :selected="checkSelected(item)"
        class="class_item_card"
        @change="itemCheckChange"
        @click="cardclick"
      />
    </van-list>
    <div class="buttonzone">
      <van-button class="buttonzone_btn" round type="default" @click="onCancel">
        取消
      </van-button>
      <van-button
        class="buttonzone_btn"
        round
        type="primary"
        @click="onConfirm"
      >
        提交
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  List,
  Cell,
  CellGroup,
  Field,
  Form,
  Search,
  NavBar,
  Button
} from 'vant'
import ItemCardGoods from '../../../components/item-card-goods'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [List.name]: List,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Form.name]: Form,
    [Search.name]: Search,
    [CellGroup.name]: CellGroup,
    [ItemCardGoods.name]: ItemCardGoods
  },
  data() {
    return {
      transitionName: 'van-slide-right',
      searchVal: '',
      filter: '',
      editable: false,
      pattern: /^1[3456789]\d{9}$/,
      goodsCache: []
    }
  },
  computed: {
    ...mapState(['goodsList', 'deliveGoodsList']),
    goodsListFilted() {
      if (this.filter === '') return this.goodsList
      else
        return this.goodsList.filter(item =>
          item.goods_name.includes(this.filter)
        )
    }
  },
  created() {
    this.goodsCache = JSON.parse(JSON.stringify(this.deliveGoodsList))
    // this.goodsListFilted = this.goodsList
  },
  methods: {
    ...mapMutations(['mSaveDeliveGoodsList']),
    checkSelected(item) {
      if (this.deliveGoodsList.findIndex(ele => ele.goods === item) >= 0)
        return true
      return false
    },
    navBack() {
      this.$router.go(-1)
    },
    onPpReset() {
      this.popupshow = false
      console.log('onPpReset')
    },
    onSubmit() {
      console.log('onSubmit')
    },
    onCancel() {
      console.log('onCancel')
      this.$router.go(-1)
    },
    onConfirm() {
      console.log('onConfirm')
      this.goodsCache.sort((a, b) => b.goods.id - a.goods.id)
      this.mSaveDeliveGoodsList(this.goodsCache)
      this.$router.go(-1)
    },
    click_add() {
      console.log('click_add')
    },
    search() {
      console.log('search')
    },
    // eslint-disable-next-line no-unused-vars
    cardclick(goods) {
      // console.log('cardclick')
      // console.log(goods)
      // let i = this.goodsCache.findIndex(ele => ele.goods === goods)
      // console.log(i)
      // if (i >= 0) {
      //   this.goodsCache.splice(i, 1)
      // } else {
      //   this.goodsCache.push({ goods: goods, num: 1 })
      // }
      // console.info(this.goodsCache)
    },
    onfilter(e) {
      // console.log('onfilter:' + e)
      this.filter = e
    },
    itemCheckChange(e) {
      if (e.checked) {
        this.goodsCache.push({ goods: e.goods, num: 1 })
      } else {
        this.goodsCache.splice(
          this.goodsCache.findIndex(ele => ele.goods === e.goods),
          1
        )
      }
      // console.info(this.goodsCache)
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .content_zone {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .info_item {
    flex: 0 0 44px;
  }
  .info_zone {
    display: flex;
    flex: 0 0 44px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 44px;
    background-color: #ffffff;
    &_price {
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
    &_weight {
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
  }
  .buttonzone {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 -3px 6px rgba(78, 93, 113, 0.08);
    &_btn {
      width: 110px;
      height: 38px;
      margin: 6px;
    }
  }
  .class_item_card {
    width: calc(100vw - 10px);
    margin: 5px;
    &_btn {
      color: #999999;
      background-color: #f5f5f5;
      border: 1px dashed #bababa;
    }
  }
}
.fixheight {
  height: calc(100% - 46px - 54px - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.mt-10 {
  margin-top: 10px;
}
.m-16 {
  margin: 16px;
}
.mt-30 {
  margin-top: 30px;
}

.item_list .van-tab--disabled {
  color: #000000;
}

.class_datetimepicker {
  border-radius: 5px;
}
</style>
